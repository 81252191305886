import { useIsMutating } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { Address, useAddressPretty, useDeliveryAddress } from '@/api/address';
import { useCutlistState } from '@/api/store';
import { AddressInfo } from '@/blocks/AddressInfo';
import { AgentQuoteHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { OwnerInfo } from '@/blocks/OwnerInfo';
import { PriceSummary } from '@/blocks/PriceSummary';
import { Button } from '@/primitives/Button';
import { Field } from '@/primitives/Field';
import { ArrowLeft, Download, Icon } from '@/primitives/Icons';
import {
  useAgentCutlistPatch,
  useAgentFinaliseQuote,
  useAgentUpdateCutlistAddress,
} from '@/queries/agent';
import { formatYYYYMMDD, getNthWorkingDayFromToday } from '@/utils/date';

import { DeliveryAddressForm } from './AccountDetails';
import styles from './AgentQuoteCheckout.module.css';

export const AgentQuoteCheckout = () => {
  const [addressEditing, setAddressEditing] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutateAsync: finaliseQuote } = useAgentFinaliseQuote();
  const { mutateAsync: patchCutlist } = useAgentCutlistPatch(id!);
  const { requestedDeliveryDate, title } = useCutlistState();

  const handleBackToPricing = () => {
    navigate(`/admin/orders/${id}/quote/price`);
  };

  const handleFinaliseQuote = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!id) return;

    try {
      await patchCutlist({
        id,
        fields: {
          requestedDeliveryDate,
          title,
        },
      });

      await finaliseQuote({ id });
      navigate('/admin/orders');
    } catch (error) {
      toast.error(t('agent.orderCheckout.finalizeQuoteError'));
      console.error(error);
    }
  };

  return (
    <div>
      <AgentQuoteHeader />
      <section className="layout">
        {/* Main content */}
        <div className={styles.orderInfo}>
          <div className="flexAlign gap-m gutter">
            <Button
              className="outline smaller"
              icon={<Icon icon={<ArrowLeft />} />}
              onClick={handleBackToPricing}
            >
              {t('agent.orderCheckout.backToPricing')}
            </Button>
            <h2>{t('agent.orderCheckout.checkout')}</h2>
          </div>
          <OwnerBlock />
          <DeliveryAddressBlock
            addressEditing={addressEditing}
            setAddressEditing={setAddressEditing}
          />
          <form id="checkoutForm" onSubmit={handleFinaliseQuote}>
            <DeliveryDateBlock />
          </form>
        </div>

        <Aside finaliseQuoteDisabled={addressEditing} />
      </section>
    </div>
  );
};

const Aside = ({
  finaliseQuoteDisabled,
}: {
  finaliseQuoteDisabled: boolean;
}) => {
  const { t } = useTranslation();
  const isMutating = useIsMutating();

  return (
    <PriceSummary>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--space-s)',
          paddingTop: 'var(--space-m)',
        }}
      >
        <Button
          variant="secondaryPill"
          className="full"
          style={{ fontWeight: 600 }}
          icon={<Icon icon={<Download />} />}
          onClick={() => {
            console.log('TODO: wire up export PDF functionality');
          }}
        >
          {t('agent.orderCheckout.exportPDF')}
        </Button>
        <Button
          variant="secondaryPill"
          className="full"
          style={{ fontWeight: 600 }}
          icon={<Icon icon={<Download />} />}
          onClick={() => {
            console.log('TODO: wire up export SAP data functionality');
          }}
        >
          {t('agent.orderCheckout.exportSAP')}
        </Button>
        <Button
          type="submit"
          form="checkoutForm"
          disabled={isMutating > 0 || finaliseQuoteDisabled}
        >
          {t('agent.orderCheckout.finalizeQuote')}
        </Button>
      </div>
    </PriceSummary>
  );
};

const OwnerBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.owner')}</h3>
      <div className={styles.blockInfo}>
        <Card>
          <OwnerInfo />
        </Card>
      </div>
    </div>
  );
};

const DeliveryDateBlock = () => {
  const minDate = formatYYYYMMDD(getNthWorkingDayFromToday(3));
  const { t } = useTranslation();
  const cutlistStore = useCutlistState();
  return (
    <div className={'opposites'} style={{ height: 'fit-content' }}>
      <h3>{t('agent.orderCheckout.deliveryDate')}</h3>
      <div className={styles.blockInfo}>
        <Field
          required
          label={''}
          type="date"
          name="requestedDeliveryDate"
          min={minDate}
          value={cutlistStore.requestedDeliveryDate || ''}
          setProp={(value) => {
            cutlistStore.setRequestedDeliveryDate(value);
          }}
          className="fullWidth lighterBorder dateInput moreXPadding"
        />
      </div>
    </div>
  );
};

const DeliveryAddressBlock = ({
  addressEditing,
  setAddressEditing,
}: {
  addressEditing: boolean;
  setAddressEditing: (value: boolean) => void;
}) => {
  const deliveryInfo = useAddressPretty();
  const { t } = useTranslation();

  const {
    setProp: setDeliveryProp,
    init,
    ...deliveryAddress
  } = useDeliveryAddress();
  const { mutate: updateAddress } = useAgentUpdateCutlistAddress();
  const { id } = useParams();

  // store initial address to reset to if user cancels on component mount
  const [initialAddress, setInitialAddress] = useState<Address>();
  useEffect(() => {
    setInitialAddress(deliveryAddress);
  }, []);

  const handleSave = async () => {
    if (!id) return;
    try {
      updateAddress({ id, address: deliveryAddress });
      setInitialAddress(deliveryAddress);
      toast.success(t('agent.orderCheckout.deliveryAddressUpdated'));
    } catch (error) {
      toast.error(t('agent.orderCheckout.deliveryAddressUpdateError'));
    } finally {
      setAddressEditing(false);
    }
  };
  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.deliveryTo')}</h3>
      <div className={styles.blockInfo}>
        {addressEditing ? (
          <Card>
            <form onSubmit={handleSave}>
              <DeliveryAddressForm
                title={''}
                address={deliveryAddress}
                setProp={setDeliveryProp}
              />
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                  marginTop: '-1rem',
                }}
              >
                <Button type="submit" variant="straight">
                  {t('common.cta.save')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    init(initialAddress as Address);
                    setAddressEditing(false);
                  }}
                >
                  {t('common.cta.cancel')}
                </Button>
              </div>
            </form>
          </Card>
        ) : (
          <Card>
            <AddressInfo
              info={deliveryInfo}
              onEditAddress={() => setAddressEditing(true)}
            />
          </Card>
        )}
      </div>
    </div>
  );
};
