import {
  CutlistOrder,
  MaterialGroup,
  PricingLineItem,
} from '@cutr/constants/cutlist';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { agentApi } from '@/api/backend/agent';
import { useMaterialGroupState } from '@/api/materialsGroup';
import {
  MaterialPriceMap,
  useAggregatedMaterialGroups,
  usePricingStore,
} from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { AgentQuoteHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { PriceSummary } from '@/blocks/PriceSummary';
import { Button } from '@/primitives/Button';
import { ArrowLeft, Cart, Icon } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useSavePriceOverride } from '@/queries/agent';
import { agentKeys } from '@/queries/keys';
import { currencyFormatter } from '@/utils/format';
import { useDebounce } from '@/utils/hooks';
import { cleanNumberInput } from '@/utils/input';

import styles from './AgentQuotePrice.module.css';

export const AgentQuotePrice = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();
  const priceStore = usePricingStore();
  const { mutateAsync: savePriceOverride } = useSavePriceOverride(id!);
  const { discountAmount, discountPercentage } = useCutlistState();

  const toCheckout = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    savePriceOverride({
      id: id!,
      discountAmount,
      discountPercentage,
      pricingLineItems: priceStore.pricingLineItems,
    }).catch((e) => {
      priceStore.setError(e);
    });

    navigate(`/admin/orders/${id}/quote/checkout`);
  };

  return (
    <form name="quote" onSubmit={toCheckout}>
      <AgentQuoteHeader />
      <section className="layout">
        <QuoteEditor />
        <Aside />
      </section>
    </form>
  );
};

const Aside = () => {
  usePricingFromQuote();

  return (
    <aside>
      <PriceSummary>
        <div className="stack" style={{ paddingTop: 'var(--space-s)' }}>
          <Button
            type="submit"
            icon={<Icon style={{ flex: '0 0 auto' }} icon={<Cart />} />}
          >
            {t('agent.quoteFlow.toCheckout')}
          </Button>
        </div>
      </PriceSummary>
    </aside>
  );
};

export function usePricingFromQuote() {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { discountAmount, discountPercentage } = useCutlistState();
  const latestOverridesRef = React.useRef<(number | null)[]>([]);

  const priceStore = usePricingStore();
  const quantityOverrides = priceStore.pricingLineItems.map(
    (pli) => pli.quantityOverride
  );

  const debouncedOverrides = useDebounce<(number | null)[]>(
    quantityOverrides,
    500,
    quantityOverrides
  );

  React.useEffect(() => {
    latestOverridesRef.current = quantityOverrides;
  }, [quantityOverrides]);

  React.useEffect(() => {
    if (!id) return;
    const queryKey = agentKeys.getPriceOverride(id, debouncedOverrides);

    const priceCache = queryClient.getQueryData<CutlistOrder>(queryKey);
    if (priceCache) {
      priceStore.setAmountVat(priceCache.totalAmountInclVAT);
      priceStore.setAmountNoVat(priceCache.totalAmountExclVAT);
      priceStore.setPricing(priceCache.pricingLineItems);
      return;
    }

    queryClient
      .fetchQuery<CutlistOrder>({
        queryKey,
        queryFn: () =>
          agentApi.getPriceOverride({
            id,
            pricingLineItems: priceStore.pricingLineItems,
          }),
      })
      .then((data) => {
        if (
          JSON.stringify(debouncedOverrides) ===
          JSON.stringify(latestOverridesRef.current)
        ) {
          priceStore.setAmountVat(data.totalAmountInclVAT);
          priceStore.setAmountNoVat(data.totalAmountExclVAT);
          priceStore.setPricing(data.pricingLineItems);
        }
      })
      .catch((e) => {
        priceStore.setError(e);
      });
  }, [...debouncedOverrides, discountAmount, discountPercentage]);

  React.useEffect(() => {
    if (!id) return;

    const queryKey = agentKeys.getPriceOverride(id, quantityOverrides);

    const priceCache = queryClient.getQueryData<CutlistOrder>(queryKey);
    if (!priceCache) return;

    priceStore.setAmountVat(priceCache.totalAmountInclVAT);
    priceStore.setAmountNoVat(priceCache.totalAmountExclVAT);
    priceStore.setPricing(priceCache.pricingLineItems);
  }, [...quantityOverrides, discountAmount, discountPercentage]);
}

const QuoteEditor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const { id } = useParams();

  return (
    <div>
      <div className="flexAlign gap-m gutter">
        <Button
          className="outline smaller"
          onClick={() => setIsConfirmDialogOpen(true)}
          icon={<Icon icon={<ArrowLeft />} />}
        >
          {'Back to cutlist'}
        </Button>
        <ConfirmationDialog
          isOpen={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          onConfirm={() => {
            navigate(`/admin/orders/${id}/quote/parts`, { replace: true });
          }}
          title={t('agent.backDialogTitle')}
          variant="alert"
        >
          {t('agent.backDialogContent')}
        </ConfirmationDialog>
        <h2>{t('agent.quoteEditor.title')}</h2>
      </div>

      <MaterialGroupQuotes />
    </div>
  );
};

const MaterialGroupQuotes = () => {
  const groups = useMaterialGroupState((state) => state.groups);

  return (
    <div className={styles.quoteContainer}>
      {groups.map((group) => (
        <MaterialGroupQuote key={group.id} group={group} />
      ))}
    </div>
  );
};

const MaterialGroupQuote = ({ group }: { group: MaterialGroup }) => {
  const materialsPriceMap: MaterialPriceMap = useAggregatedMaterialGroups();

  const getTotalAmount = (lineItems: PricingLineItem[]) => {
    if (lineItems.length === 0) {
      return '€ ---.--';
    }

    const totalAmount = lineItems.reduce(
      (acc, item) => acc + item.totalAmount,
      0
    );
    return currencyFormatter(totalAmount / 100);
  };

  const currentLineItems = materialsPriceMap[group.id] || [];
  const groupTotalAmount = getTotalAmount(currentLineItems);

  const materialLineItems = currentLineItems.filter(
    (item) => item.category === 'material'
  );
  const materialTotalAmount = getTotalAmount(materialLineItems);

  const productionCostLineItems = currentLineItems.filter(
    (item) => item.category !== 'material'
  );
  const productionCostTotalAmount = getTotalAmount(productionCostLineItems);

  return (
    <Card key={group.id}>
      <QuoteHeader groupTotalAmount={groupTotalAmount} index={group.index} />
      <div className={styles.quoteBody}>
        <QuoteSection
          title={t('agent.quoteEditor.subheaders.materials')}
          subtotal={materialTotalAmount}
          lineItems={materialLineItems}
        />
        <QuoteSection
          title={t('agent.quoteEditor.subheaders.productionCosts')}
          subtotal={productionCostTotalAmount}
          lineItems={productionCostLineItems}
        />
      </div>
    </Card>
  );
};

const QuoteHeader = ({
  groupTotalAmount,
  index,
}: {
  groupTotalAmount: string;
  index?: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <div className="flexAlign opposites">
        <h3>{t('agent.quoteEditor.materialNr', { count: index })} </h3>
        <h3>{'•'}</h3>
        <h3>{groupTotalAmount}</h3>
      </div>

      <MaterialQuoteActions />
    </div>
  );
};

const QuoteSection = ({
  title,
  subtotal,
  lineItems,
}: {
  title: string;
  subtotal: string;
  lineItems: PricingLineItem[];
}) => {
  return (
    <>
      <div className={styles.subheader}>
        <h4>{title}</h4>
        <h4>{'•'}</h4>
        <h4>{subtotal}</h4>
      </div>

      {lineItems.map((pricingLineItem) => (
        <LineItem key={pricingLineItem.id} pricingLineItem={pricingLineItem} />
      ))}

      <div className={styles.emptyRow} />
    </>
  );
};

const LineItem = ({
  pricingLineItem,
}: {
  pricingLineItem: PricingLineItem;
}) => {
  const isEditable = ((pricingLineItem: PricingLineItem) => {
    if (pricingLineItem.category === 'material') {
      return true;
    }

    if (pricingLineItem.category === 'sawing' && pricingLineItem.unit === 'm') {
      return true;
    }

    return false;
  })(pricingLineItem);

  const { setPricingLineItem } = usePricingStore();

  const handleQuantityOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPricingLineItem({
      ...pricingLineItem,
      quantityOverride: Number(cleanNumberInput(e.target.value)),
    });
  };

  return (
    <>
      <span className={cn(styles.cell, styles.padStart)}>
        {pricingLineItem.description}
      </span>

      {!isEditable && (
        <span className={cn(styles.cell, styles.alignEnd)}>
          {pricingLineItem.quantity}
        </span>
      )}

      {isEditable && (
        <Input
          name={`${pricingLineItem.id}-quantity`}
          id={`${pricingLineItem.id}-quantity`}
          type="number"
          value={pricingLineItem.quantityOverride || ''}
          placeholder={pricingLineItem.quantity.toString()}
          min="1"
          step="0.1"
          required
          className={styles.editableCell}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={handleQuantityOverride}
        />
      )}

      <strong className={cn(styles.cell, styles.alignCenter)}>{'×'}</strong>
      <span className={styles.cell}>
        {currencyFormatter(pricingLineItem.unitAmount / 100)}
      </span>
      <strong className={cn(styles.cell, styles.alignCenter)}>{'='}</strong>
      <strong className={styles.cell}>
        {currencyFormatter(pricingLineItem.totalAmount / 100)}
      </strong>
    </>
  );
};

const MaterialQuoteActions = () => {
  return <div></div>;
};
